import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | TD Garden",
    pageDescription:
      "Discover how one of the most iconic sports facilities in the world upgraded their entire POS system to SpotOn for a better fan experience.",
    title: "A state of the art garden",
    subTitle: `How TD Garden successfully upgraded its entire POS system with SpotOn.`,
    businessName: "TD Garden",
    industry: "Enterprise, Sports & entertainment",
    location: "Boston, Massachusetts",
    products: [
      spotonProducts.ENTERPRISE_POS,
      spotonProducts.SELF_SERVICE_KIOSKS,
      spotonProducts.HANDHELDS,
      spotonProducts.KITCHEN_DISPLAYS,
      spotonProducts.ONLINE_ORDERING,
    ],
    description:
      "With nearly 3.5 million visitors every year, TD Garden is the place to see the Boston Celtics (NBA), the Bruins (NHL), college sporting events, and many nationally touring music and comedy acts. In recent years, the venue upgraded its technology to SpotOn, so it could deliver a state-of-the-art experience to its fans.",
    quoteData: {
      imgName: "td-garden.png",
      quote: `As one of the most iconic sports arenas in the world, TD Garden upgraded its entire venue to SpotOn technology over a two-year period so it could accommodate the millions of sports fans and concert goers that attend events there every single year.`,
    },
    goal: `In 2018, TD Garden needed to replace its legacy POS with a modern solution with multiple integrations. The new system needed to be installed in The Garden's arena and the upcoming fan entertainment and dining complex—the Hub on Causeway.`,
    solution: `SpotOn deployed fixed POS terminals, portable and wall-mounted self-service kiosks, KDSs, mobile ordering, and reporting and inventory solutions. They also provided key integrations with essential platforms like SKIDATA, TicketMaster, and Cisco.`,
    results: {
      title: `The results`,
      stats: [
        { title: `475`, description: `POS terminals deployed` },
        {
          title: `AmpThink`,
          description: `digital menu boards installed`,
        },
        { title: `Ticketmaster`, description: `loaded value` },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "Helping fans see more touchdowns",
        imageName: "louisiana-state-university.png",
        industry: resourceIndustries.ENTERPRISE,
        tag: resourceTags.CASE_STUDY,
        date: "2022-03-10",
        href: "/case-studies/louisiana-state-university",
        goals: [
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
          resourceGoals.STREAMLINE_OPERATIONS,
        ],
    },
    {
        title: "A POS system that knocks it out of the park",
        imageName: "durham-bulls.png",
        industry: resourceIndustries.ENTERPRISE,
        tag: resourceTags.CASE_STUDY,
        date: "2022-03-31",
        href: "/case-studies/durham-bulls",
        goals: [
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
          resourceGoals.STREAMLINE_OPERATIONS,
        ],
    },
    {
        title: "Going live with multiple venues on one platform",
        imageName: "live-nation.png",
        industry: resourceIndustries.ENTERPRISE,
        tag: resourceTags.CASE_STUDY,
        date: "2022-02-17",
        href: "/case-studies/live-nation",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
  ];
  